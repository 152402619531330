import React from 'react';
// import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import SEO from 'components/Seo';

// the data prop will be injected by the GraphQL query below.
const CareerPost = ({ data, location }) => {
  // const { site } = data;
  const { careerPosts } = data; // data.careerPosts holds your post data
  const { frontmatter, excerpt, html } = careerPosts;

  // const featuredImg = getImage(frontmatter.featuredImage);
  // const imageLink = getSrc(frontmatter.featuredImage);
  // const { siteMetadata: { siteURL }} = site;

  return (
    <Layout className="career-post-container">
      <SEO
        description={excerpt}
        keywords={['kopirun', 'delivery', 'food delivery', 'crowdsource', 'contact us']}
        path={location.pathname}
        title={frontmatter.title}
      />
      <Helmet
        meta={[
          // {
          //   name: `image`,
          //   content: `${siteURL}${imageLink}`,
          // },
          // {
          //   name: `og:image`,
          //   content: `${siteURL}${imageLink}`,
          // },
          {
            name: 'og:image:alt',
            content: frontmatter.title
          }
          // {
          //   name: `twitter:image`,
          //   content: `${siteURL}${imageLink}`,
          // },
        ]}
      />
      <div className="max-w-7xl mx-auto">
        <section className="career-post flex-1 px-4 my-10 sm:px-6 lg:px-8">
          <div className="career-post-meta mb-4">
            <h1 className="text-2xl font-bold">{frontmatter.title}</h1>
            <h2 className="text-sm font-bold">{frontmatter.date}</h2>
          </div>
          {/* <div className="career-post-featured-image pb-4"> */}
          {/* <GatsbyImage image={featuredImg} alt={frontmatter.title} /> */}
          {/* </div> */}
          <div
            className="career-post-content markdown"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <div className="flex mt-10">
            <div className="m-auto">
              <div className="w-60 text-sm text-center items-center">
                <a
                  className="text-white font-bold bg-kopirun-green rounded-md py-3 px-5 hover:bg-kopirun-green-bright focus:outline-none focus:ring-2 focus:ring-opacity-50"
                  href={`mailto:recruit@kopirun.com?subject=${frontmatter.title}&body=Hey,%0D%0A%0D%0A I'm interested in applying for ${frontmatter.title}!%0D%0A%0D%0A Here's my CV attached to this email.%0D%0A%0D%0A`}
                  rel="nofollow noopener noreferrer"
                  target="_blank">
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($slug: String!) {
    site: site {
      siteMetadata {
        siteURL
      }
    }
    careerPosts: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      excerpt(pruneLength: 250)
      frontmatter {
        date(formatString: "DD MMMM, YYYY")
        slug
        title
        featuredImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

CareerPost.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.PropTypes.shape({
        siteURL: PropTypes.string
      })
    }),
    careerPosts: PropTypes.shape({
      html: PropTypes.string,
      excerpt: PropTypes.string,
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        date: PropTypes.string,
        slug: PropTypes.string,
        featuredImage: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            gatsbyImageData: PropTypes.any
          })
        })
      })
    })
  }).isRequired
};

export default CareerPost;
